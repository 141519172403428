import PageContext from '@context'
import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import React, { useContext } from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles(() => {
  return {
    abTest: {
      '&>div': { display: 'none' },
      '&[data-variation-id="control"] [class*="control"]': {
        display: 'block',
      },
      '&[data-variation-id="variation-a"] [class*="variation-a"]': {
        display: 'block',
      },
      '&[data-variation-id="variation-b"] [class*="variation-b"]': {
        display: 'block',
      },
      '&[data-variation-id="variation-c"] [class*="variation-c"]': {
        display: 'block',
      },
      '&[data-variation-id="variation-d"] [class*="variation-d"]': {
        display: 'block',
      },
      '&[data-variation-id="variation-e"] [class*="variation-e"]': {
        display: 'block',
      },
      '&[data-variation-id="variation-f"] [class*="variation-f"]': {
        display: 'block',
      },
      '&[data-variation-id="control"] img[src*="mutiny-image"]': {
        display: 'none',
      },
    },
  }
})

const ABTest = ({ blok }) => {
  const { control, id, previewVariationId, variations } = blok
  const { isInEditor } = useContext(PageContext)
  const classes = useStyles()

  const renderTest = () => {
    if (isInEditor) {
      const renderPreviewVariation = () => {
        if (previewVariationId === 'control') {
          return <div>{renderBloks(control)}</div>
        }

        const filteredPreviewVariation = variations.filter(
          (v) => v.id === previewVariationId
        )

        return renderBloks(filteredPreviewVariation)
      }

      return (
        <SbEditable content={blok}>
          <div>{previewVariationId ? renderPreviewVariation() : null}</div>
        </SbEditable>
      )
    }

    return (
      <div className={classes.abTest} data-variation-id="control" id={id}>
        <div className={`${id}-control`}>{renderBloks(control)}</div>
        {renderBloks(variations, { testId: id })}
      </div>
    )
  }

  return control && id && variations ? renderTest() : null
}

export default ABTest
